import React, { useState, useEffect } from 'react';
import './chonkyBday.css';
import VideoPlayer from '../videoPlayer';

const ChonkyBday = () => {
    const [typewriterContent, setTypewriterContent] = useState("");
    const [typewriterClass, setTypewriterClass] = useState("fade-in");
    const [currentSet, setCurrentSet] = useState("typewriter");
    const [currentSetIndex, setCurrentSetIndex] = useState(-1);
    const [currentWordIndex, setCurrentWordIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    const typewriterArray = ["Lover ❤️", "Fan ❤️", "Supporter ❤️", "Well Wisher ❤️", "Admirer ❤️", "Enthusiast ❤️"];
    // const typewriterArray = ["st ❤️"]
    const firstSet = [
        "Because of you, I feel loved...❤️",
        "Because of you, I feel alive...❤️",
        "I love you so so so much...❤️"
    ];
    const secondSet = [
        "Happy Birthday Babe...❤️",
        "Happy 27th...❤️",
        "My soon to be Wifey...❤️❤️❤️",
        "Here is a little video of us... for you... #DHARMA❤️"
    ];

    useEffect(() => {
        let timer;
        switch (currentSet) {
            case "typewriter":
                if (currentWordIndex < typewriterArray.length) {
                    const currentWord = typewriterArray[currentWordIndex];
                    if (!isDeleting && typewriterContent.length < currentWord.length) {
                        timer = setTimeout(() => {
                            setTypewriterContent(prev => prev + currentWord[prev.length]);
                        }, 120);
                    } else if (!isDeleting && typewriterContent.length === currentWord.length) {
                        timer = setTimeout(() => {
                            setIsDeleting(true);
                        }, 2000);
                    } else if (isDeleting && typewriterContent.length > 0) {
                        timer = setTimeout(() => {
                            setTypewriterContent(prev => prev.slice(0, -1));
                        }, 120);
                    } else if (isDeleting && typewriterContent.length === 0) {
                        setIsDeleting(false);
                        setCurrentWordIndex(prev => prev + 1);
                    }
                } else {
                    timer = setTimeout(() => {
                        setTypewriterClass("fade-out");
                        setTimeout(() => {
                            setCurrentSet("firstSet");
                            setCurrentSetIndex(0);
                        }, 1500);
                    }, 500);
                }
                break;
            case "firstSet":
                if (currentSetIndex < firstSet.length) {
                    timer = setTimeout(() => {
                        setCurrentSetIndex(prev => prev + 1);
                    }, 3000);
                } else {
                    timer = setTimeout(() => {
                        setCurrentSet("firstSetFadeOut");
                    }, 3000);
                }
                break;
            case "firstSetFadeOut":
                timer = setTimeout(() => {
                    setCurrentSet("secondSet");
                    setCurrentSetIndex(0);
                }, 2000);
                break;
            case "secondSet":
                if (currentSetIndex < secondSet.length) {
                    timer = setTimeout(() => {
                        setCurrentSetIndex(prev => prev + 1);
                    }, 3000);
                } else {
                    timer = setTimeout(() => {
                        setCurrentSet("secondSetFadeOut");
                    }, 3000);
                }
                break;
            case "secondSetFadeOut":
                // This is the end of the animation
                timer = setTimeout(() => {
                    setShowVideo(true)
                }, 1000);
                break;
        }

        return () => clearTimeout(timer);
    }, [typewriterContent, currentSet, currentSetIndex, currentWordIndex, isDeleting]);

    return (
        <div className="ChonkyBday">
            {currentSet === "typewriter" && (
                <div className={`Intro text text-center ${typewriterClass}`}>
                    <span className="subheading">Hello! My Dearest...</span>
                    <h1>Chonky</h1>
                    <h2>I'm your biggest <span>{typewriterContent}</span></h2>
                </div>
            )}
            {(currentSet === "firstSet" || currentSet === "firstSetFadeOut") && (
                <div className={`Intro text text-center ${currentSet === "firstSetFadeOut" ? "fade-out" : ""}`}>
                    {firstSet.slice(0, currentSetIndex + 1).map((text, index) => (
                        <h2 key={index} className={index === currentSetIndex ? "fade-in" : ""}>
                            {text}
                        </h2>
                    ))}
                </div>
            )}
            {(currentSet === "secondSet" || currentSet === "secondSetFadeOut") && (
                <div className={`Intro text text-center ${currentSet === "secondSetFadeOut" ? "fade-out" : ""}`}>
                    {secondSet.slice(0, currentSetIndex + 1).map((text, index) => (
                        <h2 key={index} className={index === currentSetIndex ? "fade-in" : ""}>
                            {text}
                        </h2>
                    ))}
                </div>
            )}
            {showVideo && <VideoPlayer />}
        </div>
    );
};

export default ChonkyBday;