import React from 'react';
import myVideo from '../Assets/chonky_bday.MP4';

const VideoPlayer = () => {
    const handleVideoEnd = () => {
        const howMuch = prompt("I love you my literal queen....❤️\n\nHow much do you love me? (I know this is your question)")
        alert(`I love you more than "${howMuch}". HAHAHA`)
    }

    return (
        <div className="video-player Intro text-center fade-in">
            <video width="300" controls autoPlay onEnded={handleVideoEnd}>
                <source src={myVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </div>
    );
};

export default VideoPlayer;