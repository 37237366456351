import React, { Fragment, useRef, useState } from 'react';
import { Container, Row, Col, Media, Image, Button } from 'react-bootstrap';
import Profile from '../../Assets/Images/profile.jpg';
import LineShimmer from '../../UI/Shimmer/Shimmer';
import './Middle.css';
import { useHistory } from 'react-router-dom';

function Middle(props) {

    const { aboutMe = null, resumeUrl = '' } = props;
    const [clickCount, setClickCount] = useState(0);
    const clickTimeout = useRef(null);
    const history  = useHistory();

    const handleClick = () => {
        // Clear any existing timeout
        clearTimeout(clickTimeout.current);

        // Increment the click count
        setClickCount((prevCount) => prevCount + 1);

        // Set up the debounce mechanism
        clickTimeout.current = setTimeout(() => {
            setClickCount(0); // Reset the click count if not clicked within 100ms
        }, 200);

        // If click count reaches 27, execute the desired function
        if (clickCount + 1 === 27) {
            handle27Clicks();
        }
    };

    const handle27Clicks = () => {
        // console.log('You clicked 27 times within 100ms each!');
        // Add any additional logic you want to execute here
        history.push('/chonky-b-h-h');
    };

    const getResumeUrl = () => {
        window.open(resumeUrl, "_blank");
    }
    return (
        <Fragment>
            <Container id="aboutMe">
                <Row>
                    <Col md={6} className="p-0 d-lg-block">
                        <Media>
                            <Image src={Profile} fluid rounded></Image>
                        </Media>
                    </Col>
                    <Col md={6} className="intro-Info">
                        <h1 className="aboutMe">
                            About Me
                        </h1>
                        <div>
                            <h5 className="intro-sec">
                                {aboutMe === null ? <LineShimmer styles={{ width: "80%", height: "6em" }} /> : aboutMe.about}
                            </h5>
                        </div>
                        <div className="info-section">
                            <ul className="about-info mt-4">
                                <li><div className="field"> Name : </div>  <span className="answer">{aboutMe === null ? <LineShimmer styles={{ width: "50%", height: "1.2em" }} /> : <span className="answer">{aboutMe.name}</span>}</span></li>
                                <li onClick={handleClick}><span className="field"> Date of Birth : </span> {aboutMe === null ? <LineShimmer styles={{ width: "50%", height: "1.2em" }} /> : <span className="answer">{aboutMe.dob}</span>}</li>
                                <li><span className="field"> Address : </span> {aboutMe === null ? <LineShimmer styles={{ width: "50%", height: "1.2em" }} /> : <span className="answer">{aboutMe.address}</span>}</li>
                                <li><span className="field"> Email : </span> <span className="answer">{aboutMe === null ? <LineShimmer styles={{ width: "50%", height: "1.2em" }} /> : aboutMe.email}</span></li>
                            </ul>
                            <div className="btn-container">
                                <Button size="lg" onClick={getResumeUrl} > Download CV </Button>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>
        </Fragment>

    )
}
export default Middle;